<template>
  <div class="people">
    <EHeader :title="title" :btns="[]" />

    <div class="people-body">
      <div class="user-info" v-if="user">
        <div class="user-avatar">
          <img :src="user.avatar" :alt="user.nickname" />
        </div>
        <div class="user-name">欢迎光临：{{user.nickname}}</div>
      </div>

      <div class="table-people">
        <h2>请问您几人用餐？</h2>
        <p>请选择人数</p>
        <ul v-if="table">
          <li v-for="(v,k) in table.peopleCount" :key="k" @click="onChooseCount(v)" :class="{'on': count === v}">{{v}}</li>
        </ul>
        <a-button class="btn" :disabled="count === 0" type="primary" @click="onSubmit">确定</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import {} from '@ant-design/icons-vue'
import {} from 'ant-design-vue'
import EHeader from '@/components/mobile/Header'
import { TableClass } from '@/apis/table'
import store from '@/store'

const table = new TableClass()
export default defineComponent({
  components: {
    EHeader
  },
  setup () {
    const user = ref()
    const table = ref()
    const title = ref('')
    const count = ref(0)
    return {
      user,
      table,
      title,
      count
    }
  },

  mounted () {
    this.user = store.state.user.userInfo
    this.getTableInfo()
  },

  methods: {
    async getTableInfo () {
      const id = await this.$store.dispatch('GetScanTableId')
      const count = await this.$store.dispatch('GetScanPeopleCount')
      const storeId = await this.$store.dispatch('GetScanStoreId')
      if (!id || !storeId) {
        this.$message.error('请扫码打开！')
        return
      }

      this.count = count || 0

      table.detail(id).then((resp) => {
        this.table = resp
        this.title = resp.name
      })
    },

    onChooseCount (count) {
      this.count = count
    },

    onSubmit () {
      this.$store.commit('SET_SCAN_PEOPLE_COUNT', this.count)
      this.$router.push({ name: 'scan-index' })
    }
  }
})
</script>

<style lang="less" scoped>
.people{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .people-body{
    flex: 1;
    display: flex;
    flex-direction: column;
    background: #ebe9e9;
    .user-info{
      padding: 0.3rem 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      .user-avatar{
        width: 1.2rem;
        height: 1.2rem;
        border-radius: 50%;
        overflow: hidden;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        border: 0.03rem solid #fff;
        img{
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      .user-name{
        font-size: 0.2rem;
        line-height: 0.6rem;
      }
    }

    .table-people{
      flex: 1;
      background: #fff;
      border-radius: 0.2rem 0.2rem 0 0;
      padding-top: 0.2rem;
      h2{
        font-size: 0.3rem;
        text-align: center;
      }
      p{
        font-size: 0.2rem;
        text-align: center;
      }
      ul{
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        list-style: none;
        width: 7.2rem;
        margin: 0 auto;
        li{
          border: 1px solid #999898;
          text-align: center;
          width: 1.6rem;
          height: 0.7rem;
          line-height: 0.7rem;
          margin: 0.1rem 0.1rem;
          border-radius: 0.35rem;
          font-size: 0.3rem;
          font-weight: 600;
          &.on{
            background: #7f76fa;
            color: #fff;
          }
        }
      }

      .btn{
        display: block;
        width: 7.2rem;
        height: 0.8rem;
        margin: 0.4rem auto 0;
        border-radius: 0.4rem;
      }
    }
  }
}
</style>
